import * as React from "react";
import "./Playlist.css";
import PlayListBannerImage from '../../../Assets/images/play-list-banner-purple.jpg';
import PlaylistModel from "../../Models/PlaylistModel";
import PlaylistCard from "./PlaylistCard";

interface PlaylistProps {
    playlists: Array<PlaylistModel>,
    addPlaylists: () => {},
    goToDetail: (id: string) => void,
    loadMore: boolean
}

const Playlist: React.FC<PlaylistProps> = ({playlists, addPlaylists, loadMore, goToDetail}) => {
    return <div className="contentSection">
        {/*<section className="banner innerBanner">*/}
        {/*    <img src={PlayListBannerImage} alt="Banner"/>*/}
        {/*    <div className="bannerText">*/}
        {/*        <div className="bannerTextWrap">*/}
        {/*            <h1>Time to create.</h1>*/}
        {/*            <p>Choose a playlist to get started.</p>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}
        <section className="playListSection" style={{marginTop: 60}}>
            <div className="container">
                <div className="sectionTitle">
                    <h2>My Playlists</h2>
                    <p>Choose Playlist to Sort</p>
                </div>
                <div className="playListRow">
                    {Array.from(playlists).map((item) => {
                        return <PlaylistCard key={item.id} playlist={item} goToDetail={goToDetail}/>
                    })}
                </div>
                <div className="playListRow loadMoreButtonDiv">
                    {
                        loadMore &&
                        <div className="addPlayListBox">
                            <div className="addPlayListRow">
                                <div className="saveBtnRow">
                                    <button onClick={addPlaylists} type="submit" className="saveBtn">Load More</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    </div>;
};

export default Playlist;