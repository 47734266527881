import * as React from "react";
import MotivationThumbImage from '../../../Assets/images/motivation-thumb.png';
import PlaylistModel from "../../Models/PlaylistModel";

interface PlaylistCardProps {
    playlist: PlaylistModel,
    goToDetail: (id: string) => void
}

const SubPlaylistCard: React.FC<PlaylistCardProps> = ({playlist, goToDetail}) => {
    let image = MotivationThumbImage;
    if (Object.keys(playlist.images).length > 0) {
        image = playlist.images[0].url;
    }
    return <div className="changePlayListItem" onClick={() => goToDetail(playlist.id)}>
        <div className="changePlayListThumb">
            <img src={image} alt="Motivation"/>
        </div>
        <div className="changeplayListName">
            <h3>{playlist.name}</h3>
            <p>by {playlist.owner.display_name}</p>
        </div>
        <div className="changeplayListTrack">
            <p>{playlist.tracks.total} Tracks</p>
        </div>
    </div>;
};

export default SubPlaylistCard;