const pad = (n: number, z?: number) => {
    z = z || 2;
    return ('00' + n).slice(-z);
};
export const msToTime = (s: number): string => {
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;

    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
};
export const msToTimeNoHours = (s: number): string => {
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;

    return pad(mins) + ':' + pad(secs);
};
