import * as React from 'react';
import Playlist from './Playlist';
import AppContext from "../../../Context/app-context";
import {Redirect, RouteComponentProps, withRouter} from "react-router";
import PlaylistModel from "../../Models/PlaylistModel";
import {getPlaylists} from "../../Helpers/Spotify";
import SubPlaylist from "../Detail/SubPlaylist";

interface PlaylistProps extends RouteComponentProps {
    minor?: boolean
}

interface PlaylistState {
    playlists: Array<PlaylistModel>,
    page: number,
    loadMore: boolean
}

class PlaylistContainer extends React.Component<PlaylistProps, PlaylistState> {
    static contextType = AppContext;

    constructor(props: PlaylistProps) {
        super(props);
        this.state = {
            playlists: [],
            page: 1,
            loadMore: true
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.addPlaylists();
    }

    goToDetail = (id: string): void => {
        this.props.history.replace({pathname: '/playlist/' + id});
    }


    addPlaylists = async () => {
        const playlists = await getPlaylists(this.state.page);
        // @ts-ignore
        if (playlists['status'] === 401) {
            this.context.logout();
        } else {
            const statePlaylists = this.state.playlists;
            for (const playlist of playlists.items) {
                statePlaylists.push(playlist);
            }
            let loadMore = this.state.loadMore;
            if (statePlaylists.length >= playlists.total) {
                loadMore = false;
            }
            this.setState({
                playlists: statePlaylists,
                page: (this.state.page + 1),
                loadMore: loadMore
            });
        }
    }

    render() {
        if (this.context.accessToken === '' || this.context.accessToken === null) {
            return <Redirect to="/"/>
        }
        if (this.props.minor) {
            return <SubPlaylist playlists={this.state.playlists} addPlaylists={this.addPlaylists}
                                loadMore={this.state.loadMore} goToDetail={this.goToDetail}/>
        } else {
            return <Playlist playlists={this.state.playlists} addPlaylists={this.addPlaylists}
                             loadMore={this.state.loadMore} goToDetail={this.goToDetail}/>;
        }
    }
}

export default withRouter(PlaylistContainer);