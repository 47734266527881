import * as React from "react";
import * as PropTypes from "prop-types";
import "./Login.css";
import LoginBannerIcon from '../../../Assets/images/login-banner-purple.jpg';
import DemoVideo from '../../../Assets/images/demo-video.mp4';
import PlayIcon from '../../../Assets/images/play-icon.svg';
import CloseIcon from '../../../Assets/images/close-icon.png';
import clsx from "clsx";

interface LayoutProps {
    auth: string,
    openModel: () => void,
    modelOpen: boolean
}

const Login: React.FC<LayoutProps> = ({auth, modelOpen, openModel}) => {
    return <div>
        <div className="contentSection" style={{height: '100vh'}}>
            <section className="banner hmBanner">
                <img src={LoginBannerIcon} alt="Banner"/>
                <div className="bannerText">
                    <div className="bannerTextWrap" style={{paddingTop: 0}}>
                        <h1>Flow music</h1>
                        <p>Build your playlists for teaching yoga or fitness class.</p>
                        <div className="bannerBtn">
                            <a target="mapFrame" href={DemoVideo} className="playBtn VideoModalOpen"
                               onClick={(e) => {
                                   e.preventDefault();
                                   openModel()
                               }}>
                                <img src={PlayIcon} alt=""/>
                            </a>
                            <a href={auth} className="loginBtn">Login</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div className={clsx({videoModal: true, open: modelOpen})}>
            <div className="modalBody">
                <button className="closeBtn" onClick={(e) => {
                    e.preventDefault();
                    openModel()
                }}>
                    <img src={CloseIcon} alt="close-icon"/>
                </button>
                {
                    modelOpen &&
                    <iframe name="mapframe" frameBorder="0" allow="autoplay" allowFullScreen id="mapframe"
                            src="https://www.youtube.com/embed/2uhB3cfLvug" title="mapframe"/>
                }
            </div>
        </div>
    </div>;
};

Login.propTypes = {
    auth: PropTypes.string.isRequired,
    openModel: PropTypes.func.isRequired,
    modelOpen: PropTypes.bool.isRequired
};

export default Login;