import * as React from 'react';
import MasterContainer from './Layouts/MasterContainer';
import {BrowserRouter as Router} from 'react-router-dom';
import {ToastProvider} from 'react-toast-notifications'

export class App extends React.Component {
    render() {
        return <ToastProvider>
            <Router>
                <MasterContainer/>
            </Router>
        </ToastProvider>;
    }
}
