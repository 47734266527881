import * as React from "react";
import TrackModel from "../../Models/TrackModel";
import {msToTimeNoHours} from "../../Helpers/Date";
import IconTrash from '../../../Assets/images/icons8-trash-208.png';
import DragIcon from "../../../Assets/images/drag-icon.svg";
import MotivationSmallIcon from "../../../Assets/images/motivation-small.png";
import DeleteIcon from "../../../Assets/images/delete-icon.svg";


interface PlaylistCardProps {
    track: TrackModel,
    deleteTrack: (id: string) => void
}

const SubPlaylistTrack: React.FC<PlaylistCardProps> = ({track, deleteTrack}) => {
    let image = '';
    if (track.track.album.images.length > 0) {
        image = track.track.album.images[0].url
    }

    let artists = [];
    if (track.track.artists.length > 0) {
        for (const artist of track.track.artists) {
            artists.push(artist.name);
        }
    }
    return <div className="changePlayListItem">
        <div className="dragIcon">
            <img src={DragIcon}/>
        </div>
        <div className="changePlayListThumb">
            <img src={image} alt="motivation"/>
        </div>
        <div className="changeplayListName">
            <h3>{track.track.name}</h3>
            <p>by {artists.join(', ')}</p>
        </div>
        <div className="changeplayListTrack">
            <p>{msToTimeNoHours(track.track.duration_ms)}</p>
            <button className="deleteIcon" onClick={() => {
                deleteTrack(track.track.id)
            }} onTouchEndCapture={() => {
                deleteTrack(track.track.id)
            }}><img src={DeleteIcon}/></button>
        </div>
    </div>
};

export default SubPlaylistTrack;
