import * as React from 'react';
import {TypeEnum} from "../Components/Enmus/TypeEnum";

export default React.createContext({
    accessToken: '',
    user: {},
    saveAccessToken: (accessToken: string, local: boolean): void => {
    },
    logout: (): void => {
    },
    message: '',
    type: TypeEnum.none,
    setMessage: (message: string, type: TypeEnum): void => {
    },
    savePlayer: (player: any, device_id: string): void => {
    },
    player: undefined,
    device_id: ''
});
