import * as React from "react";
import "./Detail.css";
import {Link, RouteComponentProps} from "react-router-dom";
import ArrowRightIcon from '../../../Assets/images/arrow-right-icon.svg';
import IconPlayAll from '../../../Assets/images/icons8-video-playlist-96.png';
import IconPlay from '../../../Assets/images/icons8-play-100.png';
import IconSkip from '../../../Assets/images/icons8-end-100.png';
import IconPause from '../../../Assets/images/pause.png';
import MusicIcon from '../../../Assets/images/music-icon.svg';
import PlaySmallIcon from '../../../Assets/images/play-small-icon.svg';
import PushIcon from '../../../Assets/images/push-icon.svg';
import NextPlayIcon from '../../../Assets/images/next-play-icon.svg';
import DragIcon from '../../../Assets/images/drag-icon.svg';
import MotivationSmallIcon from '../../../Assets/images/motivation-small.png';
import DeleteIcon from '../../../Assets/images/delete-icon.svg';
import PlaylistModel from "../../Models/PlaylistModel";
import {msToTime} from "../../Helpers/Date";
import {Bar} from "react-chartjs-2";
import FeatureModel from "../../Models/FeatureModel";
import SavePlaylist from "./SavePlaylist";
import {useMediaQuery} from 'react-responsive'
import {current_playing, getAccessToken, next_song, pauseSong, playSong, song_skip} from "../../Helpers/Spotify";
import {useToasts} from 'react-toast-notifications'
import SubPlaylistTrack from "../Detail/SubPlaylistTack";
import TrackModel from "../../Models/TrackModel";
import {ReactSortable} from "react-sortablejs";
import clsx from "clsx";


interface DetailProps extends RouteComponentProps {
    totalTracks: number,
    totalTime: number,
    playlist?: PlaylistModel,
    features: Array<FeatureModel>,
    tracks: Array<TrackModel>,
    labels: Array<string>,
    values: Array<number>,
    delta: number,
    changeSortBy: (track_feature: string) => void,
    sortBy: string,
    savePlaylist: (name: string) => void,
    sortByColor: string,
    sortByColorShade: string,
    player: any,
    device_id: string,
    savePlayer: (player: any, device_id: string) => void,
    deleteTrack: (id: string) => void,
    allPlaySong: boolean,
    playAllSongs: (value: boolean) => void,
    currentTrack: string,
    saveTrack: (track: string) => void,
    changeFeatures: (features: Array<FeatureModel>) => void,
    openListModal: () => void,
    minutesChange: (minutes: number) => void,
    widthDisSave: (width: number, dis: number, left: number) => void,
    minutes: number,
    songs_left: number,
    songs_right: number,
    songs_total: number,
    openList: boolean,
    width: number,
    dis: number,
    left: number,
}

const Detail: React.FC<DetailProps> = (props) => {
    const {addToast} = useToasts();
    let name = '';
    let owner = '';
    if (props.playlist !== undefined) {
        name = props.playlist.name;
        owner = props.playlist.owner.display_name;
    }
    const barData = {
        labels: props.labels,
        datasets: [
            {
                backgroundColor: props.sortByColor,
                borderColor: props.sortByColorShade,
                borderWidth: 1,
                hoverBackgroundColor: props.sortByColor,
                hoverBorderColor: props.sortByColorShade,
                data: props.values,
            }
        ]
    };
    const isMobile = useMediaQuery({query: '(max-width: 768px)'});

    const tracks = {};
    for (const track of props.tracks) {
        // @ts-ignore
        tracks[track.track.id] = track;
    }

    //for playing tracks
    let token = getAccessToken();
    if (token != null) {
        token = token.replace('Bearer ', '');
    }

    const play_song = async (device_id: string, uris: Array<string>, position_ms = 60) => {
        let main_uris: Array<string> = [];
        main_uris = uris;
        let response = await playSong(device_id, main_uris, position_ms);
        // @ts-ignore
        if (response.error !== undefined) {
            // @ts-ignore
            let message = response.error.message;
            // @ts-ignore
            if (response.error.reason == 'PREMIUM_REQUIRED') {
                message = 'Sorry! This feature requires Spotify Premium Account.'
            }
            addToast(message, {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    };

    const play_paused_song = async (device_id: string) => {
        let response = await playSong(device_id, [], 0);
        // @ts-ignore
        if (response.error !== undefined) {
            // @ts-ignore
            let message = response.error.message;
            // @ts-ignore
            if (response.error.reason == 'PREMIUM_REQUIRED') {
                message = 'Sorry! This feature requires Spotify Premium Account.'
            }
            addToast(message, {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    };

    const pause_song = async (device_id: string) => {
        let song = await current_playing();
        // @ts-ignore
        props.saveTrack(song.item.uri);
        let response = await pauseSong(device_id);
        // @ts-ignore
        if (response.error !== undefined) {
            // @ts-ignore
            let message = response.error.message;
            // @ts-ignore
            if (response.error.reason == 'PREMIUM_REQUIRED') {
                message = 'Sorry! This feature requires Spotify Premium Account.'
            }
            addToast(message, {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    };

    const skipSong = async () => {
        let song = await current_playing();
        // @ts-ignore
        if (song.item != undefined) {
            // @ts-ignore
            let duration = song.item.duration_ms;
            // @ts-ignore
            if (song.progress_ms > duration - 15000) {
                let response = await next_song(props.device_id);
            } else {
                // @ts-ignore
                let response = await song_skip(props.device_id, duration - 15000);
            }
        }
    }

    const createPlayer = (uris: Array<string>, position_ms = 60) => {
        // @ts-ignore
        let player = new Spotify.Player({
            name: 'Detail music play',
            getOAuthToken: (callback: any) => {
                callback(token);
            },
        });
        player.connect().then((success: boolean) => {
            if (success) {
                console.log('The Web Playback SDK successfully connected to Spotify!');
            }
        });

        // @ts-ignore
        player.addListener('ready', ({device_id}) => {
            props.savePlayer(player, device_id);
            play_song(device_id, uris, position_ms);
        });
    }

    let left = 0;
    let width = 0;
    if (props.width != 0) {
        left = props.left - props.dis + props.songs_left * (props.dis + props.width);
        width = props.songs_total * props.width + (props.songs_total - 1) * props.dis;
    }

    let parts1 = props.minutes / 5;

    let parts2 = parts1 * 2;
    let parts3 = parts1 * 3;
    let parts4 = parts1 * 4;
    let parts5 = parts1 * 5;


    const barOptions = {
        legend: {
            display: false
        },
        tooltips: {
            enabled: true,
        },
        scales: {
            xAxes: [{
                display: !isMobile,
                ticks: {
                    callback: function (value: any, index: any, values: any) {
                        let parts = value.split(' ');
                        parts = parts.splice(0, 4);
                        return parts.join(' ');
                    },
                    maxRotation: 90,
                    minRotation: 90
                },
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: props.sortBy ? (props.sortBy.charAt(0).toUpperCase() + props.sortBy.replace("_", " ").slice(1))  : "Tempo"
                }
            }],
        },
        onClick: (item: any, i: any) => {
            if (i[0] != undefined) {
                let index = i[0]._index;
                props.playAllSongs(true);
                if (props.player != undefined) {
                    play_song(props.device_id, [props.features[index].uri]);
                } else {
                    createPlayer([props.features[index].uri]);
                }
            }
        },
        animation: {
            onComplete: function (item: any) {
                let meta = item.chart.getDatasetMeta(0);
                let width = 0;
                let dis = 0;
                let left = 0;
                if (meta.data[0] != undefined) {
                    left = meta.data[0]._model.x;
                    width = meta.data[0]._model.width;
                    dis = meta.data[1]._model.x - (meta.data[0]._model.x + width);
                }
                if (width != 0) {
                    props.widthDisSave(width, dis, left);
                }
            }
        },
    };

    let playlist_url = '';
    if (props.playlist != null) {
        playlist_url = props.playlist.external_urls.spotify;
    }

    console.log(props)
    return <div className="contentSection sortbycontentSec">
        <section className="sortbySec">
            <div className="secWrapper">
                <div className="backBtnRow">
                    <Link to="/playlist">
                        <span><img src={ArrowRightIcon} alt=""/></span>
                        This is your playlist, {name}
                    </Link>
                </div>
                <div className="sortByCardRow">
                    <div className={clsx({"sortByCardColRight": true, open: props.openList})}>
                        <div className="sortByCard">
                            <div className="mobileDragHendle" onClick={async () => {
                                await props.openListModal()
                            }}>
                                <span className="dragHendle" id="playListHendle"></span>
                            </div>
                            <div className="changeplayListRow">
                                <div className="playListHead">
                                    <h2>Workstation</h2>
                                    <div className="playIconList">
                                        <button className="controlBtn" type="button" onClick={async () => {
                                            let uris: Array<string> = [];
                                            Array.from(props.features).map((track: FeatureModel) => {
                                                uris.push(track.uri);
                                            })
                                            await props.playAllSongs(true);
                                            if (props.player != undefined) {
                                                play_song(props.device_id, uris, 0.1);
                                            } else {
                                                createPlayer(uris, 0.1);
                                            }
                                        }}>
                                            <img src={MusicIcon}/>
                                        </button>
                                        {
                                            !props.allPlaySong &&
                                            <button className="controlBtn" type="button" id="playMusic" onClick={async () => {
                                                await props.playAllSongs(true);
                                                play_paused_song(props.device_id);
                                            }}>
                                                <img src={PlaySmallIcon}/>
                                            </button>
                                        }
                                        {
                                            props.allPlaySong &&
                                            <button className="controlBtn" type="button" id="playMusic" onClick={async () => {
                                                await props.playAllSongs(false);
                                                pause_song(props.device_id);
                                            }}>
                                                <img src={PushIcon}/>
                                            </button>
                                        }
                                        <button className="controlBtn" type="button" onClick={() => {
                                            skipSong();
                                        }}>
                                            <img src={NextPlayIcon}/>
                                        </button>
                                    </div>
                                </div>
                                <div className="changePlayList" style={{height: '95vh'}}>
                                    <ReactSortable
                                        list={props.features}
                                        setList={async (features) => {
                                            await props.changeFeatures(features);
                                        }}
                                    >
                                        {
                                            Array.from(props.features).map((track: FeatureModel) => {
                                                // @ts-ignore
                                                return <SubPlaylistTrack key={track.id} track={tracks[track.id]} deleteTrack={props.deleteTrack}/>
                                            })
                                        }
                                    </ReactSortable>
                                </div>
                            </div>
                        </div>
                        {/*<SubPlaylist {...props} minor={true}/>*/}
                    </div>
                    <div className="sortByCardColLeft">
                        <div className="sortByCard">
                            <div className="sortByChartBox">
                                <div className="sortBoxHead">
                                    <div className="sleepCardHead">
                                        <h2>{name}</h2>
                                        <span>by {owner}</span>
                                    </div>
                                    <div className="sleepTimeDetails">
                                        <div className="sleepTimeDetItem">
                                            <div className="item">
                                                <h3>Total Time</h3>
                                                <p>{msToTime(props.totalTime)}</p>
                                            </div>
                                        </div>
                                        <div className="sleepTimeDetItem">
                                            <div className="item">
                                                <h3>Avg. Delta</h3>
                                                <p>{props.delta}%</p>
                                            </div>
                                        </div>
                                        <div className="sleepTimeDetItem">
                                            <div className="item">
                                                <h3>Total Tracks</h3>
                                                <p>{props.totalTracks}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sortByBtnList">
                                    <div className="sortByTitle">
                                        <h1>Sort by</h1>
                                    </div>
                                    <button type="button" className="btn btn-purple" onClick={() => props.changeSortBy('tempo')}>
                                        Tempo
                                    </button>
                                    <button type="button" className="btn btn-pink" onClick={() => props.changeSortBy('energy')}>
                                        Energy
                                    </button>
                                    <button type="button" className="btn btn-lightBlue" onClick={() => props.changeSortBy('danceability')}>
                                        Danceability
                                    </button>

                                    {/*<button type="button" className="btn btn-primary" onClick={() => props.changeSortBy('loudness')}>Loudness</button>*/}

                                    <button type="button" className="btn btn-green" onClick={() => props.changeSortBy('valence')}>
                                        Mood
                                    </button>
                                    <button type="button" className="btn btn-lightPink" onClick={() => props.changeSortBy('instrumentalness')}>
                                        Instrumentalness
                                    </button>
                                    {/*<button type="button" className="btn btn-lightBlue" onClick={() => props.changeSortBy('speechiness')}>*/}
                                    {/*    Talkability*/}
                                    {/*</button>*/}
                                    <button type="button" className="btn btn-specialred" onClick={() => props.changeSortBy('special_sauce')}>
                                        Special Sauce
                                    </button>
                                </div>
                                <div className="charteBox">
                                    <div className="chartFillterRow">
                                        <div className="chartFillterLeft">
                                            <input type="text" value={props.minutes} className="formControl" min="1" pattern="[0-9]*" onChange={(e) => {
                                                let value = e.currentTarget.value;
                                                if (value != '') {
                                                    if (/^\d+$/.test(value)) {
                                                        props.minutesChange(parseInt(value));
                                                    }
                                                }
                                            }}/>
                                            <label>Minutes</label>
                                        </div>
                                        <div className="chartFillterRight">
                                            <SavePlaylist name={name} savePlaylist={props.savePlaylist} sortBy={props.sortBy} url={playlist_url}/>
                                        </div>
                                    </div>
                                    {/*<img src={ChartImage} alt="Chart"/>*/}
                                    <div className="chartContainer" style={{position: 'relative'}}>
                                        <div className="chartjs-size-monitor">
                                            <div className="chartjs-size-monitor-expand">
                                                <div className=""></div>
                                            </div>
                                            <div className="chartjs-size-monitor-shrink">
                                                <div className=""></div>
                                            </div>
                                        </div>
                                        <Bar data={barData} options={barOptions}/>
                                        {/*<canvas height="976" width="1952" className="chartjs-render-monitor"*/}
                                        {/*        style="display: block; width: 976px; height: 488px; position: relative; z-index: 110;"></canvas>*/}
                                        <div className="musicTreckBg"
                                             style={{
                                                 background: 'rgba(225, 230, 239, 0.5)',
                                                 height: 'calc(100% - 7px)',
                                                 position: "absolute",
                                                 top: '7px',
                                                 width: width + 'px',
                                                 left: left + 'px',
                                                 zIndex: 1,
                                                 display: width > 0 ? "flex" : 'none',
                                                 padding: '2px',
                                                 justifyContent: "space-between"
                                             }}>
                                            {
                                                !isMobile &&
                                                <>
                                                    <p style={{color: '#5a6075', fontSize: '13px', fontWeight: 600}}>0</p>
                                                    <p style={{color: '#5a6075', fontSize: '13px', fontWeight: 600}}>{parts1}</p>
                                                    <p style={{color: '#5a6075', fontSize: '13px', fontWeight: 600}}>{parts2}</p>
                                                    <p style={{color: '#5a6075', fontSize: '13px', fontWeight: 600}}>{parts3}</p>
                                                    <p style={{color: '#5a6075', fontSize: '13px', fontWeight: 600}}>{parts4}</p>
                                                    <p style={{color: '#5a6075', fontSize: '13px', fontWeight: 600}}>{parts5}</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>;
};

Detail.propTypes = {};

export default Detail;
