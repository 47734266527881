import * as React from 'react';
import Login from './Login';
import {getAccessToken, parseHash, spotifyAuthLink} from "../../Helpers/Spotify";
import {Redirect, RouteComponentProps} from "react-router";
import AppContext from '../../../Context/app-context';

interface LoginProps extends RouteComponentProps {

}

interface LoginState {
    modelOpen: boolean
}

export default class LoginContainer extends React.Component<LoginProps, LoginState> {
    static contextType = AppContext;

    constructor(props: LoginProps) {
        super(props);
        this.state = {
            modelOpen: false
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        let token = getAccessToken();
        if (token == null) {
            const parsedHash = parseHash(this.props.history.location.hash);
            if (parsedHash !== {}) {
                if (parsedHash.access_token !== undefined) {
                    token = parsedHash.access_token;
                    window.location.hash = '';
                    this.context.saveAccessToken(token, true);
                }
            }
        } else {
            this.context.saveAccessToken(token, false);
        }
        if (this.context.accessToken !== '') {
            this.props.history.push('/playlist');
        }
    }

    openModel = () => {
        this.setState({
            modelOpen: !this.state.modelOpen
        })
    };

    render() {
        if (this.context.accessToken !== '') {
            return <Redirect to="/playlist"/>
        }
        return <Login auth={spotifyAuthLink()} openModel={this.openModel} modelOpen={this.state.modelOpen}></Login>
    }
}