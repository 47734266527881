import * as React from "react";
import "./SubPlaylist.css";
import PlaylistModel from "../../Models/PlaylistModel";
import SubPlaylistCard from "./SubPlaylistCard";

interface SUbPlaylistProps {
    playlists: Array<PlaylistModel>,
    addPlaylists: () => {},
    goToDetail: (id: string) => void,
    loadMore: boolean
}

const SubPlaylist: React.FC<SUbPlaylistProps> = ({playlists, addPlaylists, loadMore, goToDetail}) => {
    return <div className="sortByCard changeplayListCard">
        <div className="changeplayListRow">
            <div className="playListHead">
                <h2>Change Playlist</h2>
            </div>
            <div className="changePlayList">
                {
                    Array.from(playlists).map((item) => {
                        return <SubPlaylistCard key={item.id} playlist={item} goToDetail={goToDetail}/>
                    })
                }
            </div>
            <div className="playListRow loadMoreButtonDiv">
                {
                    loadMore &&
                    <div className="addPlayListBox">
                        <div className="addPlayListRow">
                            <div className="saveBtnRow">
                                <button onClick={addPlaylists} type="submit" className="saveBtn">Load More</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>;
};

export default SubPlaylist;