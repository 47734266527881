import * as React from "react";
import "./Founders.css";
import Grid from "@material-ui/core/Grid";
import FounderThumbnail from "./FounderThumbnail";
import FoundersModel from "../../Models/FoundersModel";

interface FoundersProps {
  foundersArray: Array<FoundersModel>;
}

const Founders: React.FC<FoundersProps> = ({ foundersArray }) => {
  const search = (e: React.FormEvent<EventTarget>): void => {
    let target = e.target as HTMLInputElement;
    console.log(target.value);
  };
  return (
    <div className="container">
      <div className="founders">
        <h1>Founders</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <form>
          <input
            type="text"
            id="searchFounders"
            placeholder="Keywords"
            onChange={search}
          />
        </form>
        <Grid container spacing={2} className="gridContainer">
          {Array.from(foundersArray).map(e => {
            return <FounderThumbnail founder={e} />;
          })}
        </Grid>
      </div>
    </div>
  );
};

export default Founders;
