import * as React from "react";
import Master from "./Master";
import AppContext from "../../Context/app-context";
import {accessTokenSave, getAccessToken, removeAccessToken, saveUserData, userData} from "../Helpers/Spotify";
import {TypeEnum} from "../Enmus/TypeEnum";

interface MasterProps {

}

interface MasterState {
    accessToken: string,
    user: object,
    message: string,
    type: TypeEnum,
    player: any,
    device_id: string
}

export default class MasterContainer extends React.Component<MasterProps, MasterState> {
    constructor(props: MasterProps) {
        super(props);
        let token = getAccessToken();
        if (token == null) {
            token = '';
        }
        this.state = {
            accessToken: token,
            user: {},
            message: '',
            type: TypeEnum.none,
            player: undefined,
            device_id: ''
        }
    }

    async componentDidMount() {
        let token = getAccessToken();
        let user = {};
        if (token == null) {
            token = '';
        } else {
            user = await userData();
            // @ts-ignore
            if (user['status'] !== undefined && user['status'] === 401) {
                token = '';
                user = {};
            }
        }
        this.setState({
            ...this.state,
            accessToken: token,
            user: user,
        });
    }

    saveAccessToken = async (accessToken: string, local: boolean) => {
        if (local) {
            accessTokenSave(accessToken);
        }
        // console.log(accessToken);
        const user = await userData();
        await saveUserData();
        this.setState({
            ...this.state,
            accessToken: accessToken,
            user: user,
        });
    };
    logout = () => {
        removeAccessToken();
        this.setState({
            accessToken: '',
            user: {},
            message: '',
            type: TypeEnum.none,
        });
    };

    setMessage = (message: string, type: TypeEnum): void => {
        this.setState({
            ...this.state,
            message: message,
            type: type
        });
    };

    savePlayer = (player: any, device_id: string): void => {
        this.setState({
            ...this.state,
            player: player,
            device_id: device_id
        })
    }


    render() {
        return <AppContext.Provider value={{
            accessToken: this.state.accessToken,
            user: this.state.user,
            saveAccessToken: this.saveAccessToken,
            logout: this.logout,
            message: this.state.message,
            type: this.state.type,
            setMessage: this.setMessage,
            player: this.state.player,
            device_id: this.state.device_id,
            savePlayer: this.savePlayer
        }}>
            <Master/>
        </AppContext.Provider>;
    }
}
