const APPEND_KEY = 'SPOTIFY_'

const keyAppend = (key: string): string => {
    return APPEND_KEY + key;
};

export const store = (key: string, value: string) => {
    key = keyAppend(key);
    localStorage.setItem(key, value);
};

export const remove = () => {
    localStorage.clear();
};

export const get = (key: string, defaultValue?: string): string | null => {
    key = keyAppend(key);
    let value = localStorage.getItem(key);
    if (value == null) {
        if (defaultValue !== undefined) {
            value = defaultValue;
        }
    }
    return value;
};