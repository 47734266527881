import React, {PureComponent} from 'react';
import Contact from './Contact';
import AppContext from "../../../Context/app-context";
import {RouteComponentProps, withRouter} from "react-router";

interface ContactProps extends RouteComponentProps {
}

interface ContactState {
}

class ContactContainer extends PureComponent<ContactProps, ContactState> {
  static contextType = AppContext;

  constructor(props: ContactProps) {
    super(props);
    this.state = {}
  }

  render() {
    return <Contact/>
  }
}

export default withRouter(ContactContainer);

