import * as React from "react";
import Header from "./Header";

interface HeaderContainerProps {

}

interface HeaderContainerState {
    openModel: boolean
}

export default class HeaderContainer extends React.Component<HeaderContainerProps, HeaderContainerState> {

    constructor(props: HeaderContainerProps) {
        super(props);
        this.state = {
            openModel: false
        }
    }

    modelOpen = () => {
        this.setState({
            openModel: !this.state.openModel
        });
    };

    render() {
        return <Header openModel={this.state.openModel} modelOpen={this.modelOpen}/>;
    }
}