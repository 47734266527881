import * as React from "react";
import Grid from "@material-ui/core/Grid";
import FoundersModel from "../../Models/FoundersModel";

interface FoundersThumbnailProps {
  founder: FoundersModel;
}

const FoundersThumbnail: React.FC<FoundersThumbnailProps> = ({ founder }) => {
  return (
    <Grid item xs={12} sm={6} md={3} className="founder">
      <div
        className="founderPicture"
        style={{
          backgroundImage: `url(${founder.image})`
        }}
      >
        <div
          className="nationality"
          style={{
            backgroundImage: `url(${founder.countryImage})`
          }}
        ></div>
      </div>
      <p id="founderName">{founder.name}</p>
      <p id="affiliation">{founder.affiliation}</p>
    </Grid>
  );
};

export default FoundersThumbnail;

// https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg
