import * as React from 'react';
import {Redirect, RouteComponentProps} from "react-router";
import _ from 'lodash';
import Detail from './Detail';
import AppContext from '../../../Context/app-context';
import TrackModel from "../../Models/TrackModel";
import {createPlaylist, getFeatureColors, getPlaylist, getPlaylistTracks, getTracksInfo, savePlaylistTracks} from "../../Helpers/Spotify";
import PlaylistModel from "../../Models/PlaylistModel";
import FeatureModel from "../../Models/FeatureModel";
import {TypeEnum} from "../../Enmus/TypeEnum";

interface DetailProps extends RouteComponentProps {

}

interface DetailState {
    playlist?: PlaylistModel,
    tracks: Array<TrackModel>,
    totalTime: number,
    id: string,
    features: Array<FeatureModel>,
    sortedFeatures: Array<FeatureModel>,
    labels: Array<string>,
    values: Array<number>,
    delta: number,
    minutes: number,
    sortBy: string,
    sortByColor: string,
    sortByColorShade: string,
    currentTrack: string,
    allPlaySong: boolean,
    onlyPlaySong: boolean,
    openList: boolean,
    songs_left: number,
    songs_right: number,
    songs_total: number,
    width: number,
    dis: number,
    left: number,
}

interface TrackNames {
    [id: string]: string;
}

export default class DetailContainer extends React.Component<DetailProps, DetailState> {
    static contextType = AppContext;

    constructor(props: DetailProps) {
        super(props);
        // @ts-ignore
        const id = this.props.match.params.id;
        this.state = {
            labels: [],
            values: [],
            features: [],
            sortedFeatures: [],
            tracks: [],
            totalTime: 0,
            delta: 0,
            minutes: 0,
            songs_left: 0,
            songs_right: 0,
            songs_total: 0,
            left: 0,
            width: 0,
            dis: 0,
            id: id,
            sortBy: '',
            currentTrack: '',
            sortByColor: 'rgba(177,74,253)',
            sortByColorShade: 'rgba(177,74,253,1)',
            allPlaySong: false,
            onlyPlaySong: false,
            openList: false
        }
    }

    componentDidMount(): void {
        this.getTracks(this.state.id);
    }

    componentDidUpdate(prevProps: Readonly<DetailProps>, prevState: Readonly<DetailState>, snapshot?: any): void {
        // @ts-ignore
        const id = this.props.match.params.id;
        if (prevState.id !== id) {
            window.scrollTo(0, 0);
            this.setState({
                values: [],
                labels: [],
                features: [],
                sortedFeatures: [],
                tracks: [],
                totalTime: 0,
                delta: 0,
                id: id,
                sortBy: '',
                sortByColor: 'rgba(177,74,253)',
                sortByColorShade: 'rgba(177,74,253,1)'
            });
            this.getTracks(id);
            setTimeout(() => {
                this.context.setMessage('', TypeEnum.none);
            }, 3000);
        }
    }

    getTracks = async (playlist_id: string) => {
        const tracks = await this.getAllTracks(playlist_id);
        const playlist = await getPlaylist(playlist_id);
        let totalTime = tracks.reduce((intial: number, item: TrackModel) => {
            return intial + item.track.duration_ms;
        }, 0);

        const features = await this.getAllFeatures(tracks);
        const labels = await this.labelsFromFeatures(features, tracks);
        const values = await this.valuesFromFeatures(features);
        const delta = await this.calculateDelta(values);
        this.setState({
            playlist: playlist,
            tracks: tracks,
            totalTime: totalTime,
            id: this.state.id,
            features: features,
            sortedFeatures: features,
            labels: labels,
            values: values,
            delta: delta,
            sortBy: this.state.sortBy,
            sortByColor: this.state.sortByColor,
            sortByColorShade: this.state.sortByColorShade,
        });
    };


    getAllTracks = async (playlist_id: string) => {
        let page = 1;

        let tracks = await getPlaylistTracks(playlist_id, page);
        const stateTracks = [];
        for (const track of tracks.items) {
            stateTracks.push(track);
        }
        while (stateTracks.length < tracks.total) {
            page++;
            tracks = await getPlaylistTracks(playlist_id, page);
            for (const track of tracks.items) {
                stateTracks.push(track);
            }
        }
        return stateTracks;
    };

    getAllFeatures = async (tracks: Array<TrackModel>) => {
        const all_tracks = Object.assign([], tracks);
        let features = [];
        while (all_tracks.length > 0) {
            let sub_tracks = all_tracks.splice(0, 100);
            const track_ids = sub_tracks.reduce((intial: string, item: TrackModel) => {
                return intial + item.track.id + ','
            }, '');
            let sub_features = await getTracksInfo(track_ids);
            for (let feature of sub_features.audio_features) {
                if (feature.loudness < 0) {
                    feature.loudness = 60 + feature.loudness;
                }
                features.push(feature);
            }
        }
        return features;
    };

    labelsFromFeatures = (features: Array<FeatureModel>, tracks: Array<TrackModel>): Array<string> => {
        const elements: Array<string> = [];
        const track_names: TrackNames = {};
        for (const track of tracks) {
            track_names[track.track.id] = track.track.name;
        }
        for (const feature in features) {
            elements.push(track_names[features[feature].id]);
        }
        return elements;
    };

    valuesFromFeatures = (features: Array<FeatureModel>, sortBy?: string): Array<number> => {
        const elements: Array<number> = [];
        for (const feature of features) {
            if (sortBy !== undefined && sortBy !== '') {
                if (sortBy === 'special_sauce') {
                    // @ts-ignore
                    elements.push(((feature.tempo + feature.energy + feature.loudness) / 3).toFixed(2));
                } else {
                    // @ts-ignore
                    elements.push(feature[sortBy]);
                }
            } else {
                elements.push(feature.tempo);
            }
        }
        return elements;
    };

    calculateDelta = (values: Array<number>): number => {
        let delta = values.reduce(function (result: Array<number>, current: number, index: number) {
            index && result.push(current - values[index - 1]);
            return result;
        }, []);
        let average = delta.reduce(function (a, b) {
            return a + b;
        }, 0);
        return Math.abs(parseFloat(average.toFixed(2)));
    };

    changeSortBy = async (track_feature: string, recalculate = false, sort = false) => {
        // let sortBy = 'tempo'
        let sortBy = track_feature

        if (sortBy === this.state.sortBy && !recalculate) {
            sortBy = '';
        }
        let features = this.state.features;

        if (!sort) {
            if (sortBy !== '') {
                features = await this.bsort(features, sortBy);
            }
        } else {
            features = this.state.sortedFeatures;
        }
        const labels = this.labelsFromFeatures(features, this.state.tracks);
        const values = this.valuesFromFeatures(features, sortBy);
        const delta = this.calculateDelta(values);
        const sortByColor = this.getColors(sortBy);
        const sortByColorShade = this.getColors(sortBy, 1);
        this.setState({
            ...this.state,
            labels: labels,
            values: values,
            sortedFeatures: features,
            delta: delta,
            sortBy: sortBy,
            sortByColor: sortByColor,
            sortByColorShade: sortByColorShade,
        });
    };

    bsort = async (features: Array<FeatureModel>, sortBy: string) => {
        let sub_features = Object.assign([], features);
        if (sortBy === 'special_sauce') {
            sub_features = _.orderBy(sub_features, function (o) {
                // @ts-ignore
                return (parseFloat(o.tempo) + parseFloat(o.energy) + parseFloat(o.loudness)) / 3;
            }, 'asc');
        } else {
            sub_features = _.orderBy(sub_features, sortBy, 'asc');
        }
        let part1: Array<FeatureModel> = [];
        let part2: Array<FeatureModel> = [];
        for (const index in sub_features) {
            if (parseInt(index) % 2 === 1) {
                part1.push(sub_features[index]);
            } else {
                part2.push(sub_features[index]);
            }
        }
        if (sortBy === 'special_sauce') {
            part1 = _.orderBy(part1, function (o) {
                // @ts-ignore
                return (parseFloat(o.tempo) + parseFloat(o.energy) + parseFloat(o.loudness)) / 3;
            }, 'asc');
            part2 = _.orderBy(part2, function (o) {
                // @ts-ignore
                return (parseFloat(o.tempo) + parseFloat(o.energy) + parseFloat(o.loudness)) / 3;
            }, 'desc');
        } else {
            part1 = _.orderBy(part1, sortBy, 'asc');
            part2 = _.orderBy(part2, sortBy, 'desc');
        }

        let half_minute1 = this.state.minutes * 60 * 1000 / 2;
        let half_minute2 = this.state.minutes * 60 * 1000 / 2;

        let songs_left = 0;
        let songs_right = 0;

        for (const track of _.orderBy(part1, sortBy, 'desc')) {
            if (half_minute1 > 0) {
                half_minute1 = half_minute1 - (60 * 5 * 1000);//track.duration_ms
                songs_left++;
            }
        }

        for (const track of part2) {
            if (half_minute2 > 0) {
                half_minute2 = half_minute2 - (60 * 5 * 1000);//track.duration_ms
                songs_right++;
            }
        }

        await this.setState({
            ...this.state,
            songs_left: part1.length - songs_left,
            songs_right: songs_right,
            songs_total: songs_left + songs_right,
        });

        return _.concat(part1, part2);
    };

    getColors = (sortBy: string, shade?: number) => {
        if (sortBy === '') {
            sortBy = 'tempo';
        }
        const color = getFeatureColors(sortBy);
        if (shade === undefined) {
            return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
        } else {
            return `rgb(${color[0]},${color[1]},${color[2]},${shade})`;
        }
    };

    savePlaylist = async (name: string) => {
        if (name === '') {
            this.context.setMessage('Name field is required.', TypeEnum.danger)
        } else {
            const playlist = await createPlaylist(name);
            let features = this.state.sortedFeatures;
            let uris = _.map(features, 'uri');

            while (uris.length > 0) {
                let sub_uris = uris.splice(0, 100);
                await savePlaylistTracks(playlist.id, sub_uris);
            }

            this.context.setMessage('Saved to Spotify!', TypeEnum.success);

            this.props.history.push('/playlist/' + playlist.id);
        }
    };

    deleteTrack = async (id: string) => {
        const features = this.state.features;
        for (const i in features) {
            const feature = features[i];
            if (feature.id === id) {
                // @ts-ignore
                features.splice(i, 1);
            }
        }
        const sortedFeatures = this.state.sortedFeatures;
        for (const i in sortedFeatures) {
            const feature = sortedFeatures[i];
            if (feature.id === id) {
                // @ts-ignore
                sortedFeatures.splice(i, 1);
            }
        }
        await this.setState({
            ...this.state,
            features: features,
            sortedFeatures: sortedFeatures,
        }, ()=>this.changeSortBy(this.state.sortBy, true, true));
    };

    playAllSongs = async (value: boolean) => {
        if (value == false) {
            await this.setState({
                ...this.state,
                allPlaySong: value,
                currentTrack: ''
            })
        } else {
            await this.setState({
                ...this.state,
                allPlaySong: value
            })
        }
    }

    saveTrack = async (value: string) => {
        await this.setState({
            ...this.state,
            currentTrack: value
        })
    }

    changeFeatures = async (features: Array<FeatureModel>) => {
        const labels = await this.labelsFromFeatures(features, this.state.tracks);
        const values = await this.valuesFromFeatures(features);
        const delta = await this.calculateDelta(values);
        await this.setState({
            ...this.state,
            labels: labels,
            values: values,
            delta: delta,
            sortedFeatures: features,
        })
    }

    openListModel = async () => {
        let openList = true;
        if (this.state.openList) {
            openList = false;
        }
        await this.setState({
            ...this.state,
            openList: openList
        })
    };

    minutesChange = async (minutes: number) => {
        await this.setState({
            ...this.state,
            minutes: minutes
        });
        await this.changeSortBy(this.state.sortBy, true);
    };
    widthDisSave = async (width: number, dis: number, left: number) => {
        await this.setState({
            ...this.state,
            width: width,
            dis: dis,
            left: left,
        });
        if (width != this.state.width || dis != this.state.dis || left != this.state.left) {
            // await this.changeSortBy(this.state.sortBy, true);
        }
    };

    render() {
        if (this.context.accessToken === '') {
            return <Redirect to="/"/>
        }
        return <Detail totalTracks={this.state.features.length} tracks={this.state.tracks} totalTime={this.state.totalTime} playlist={this.state.playlist}
                       features={this.state.sortedFeatures} labels={this.state.labels} values={this.state.values} delta={this.state.delta} changeSortBy={this.changeSortBy}
                       sortBy={this.state.sortBy} savePlaylist={this.savePlaylist} sortByColor={this.state.sortByColor} sortByColorShade={this.state.sortByColorShade}
                       player={this.context.player} savePlayer={this.context.savePlayer} device_id={this.context.device_id} deleteTrack={this.deleteTrack}
                       playAllSongs={this.playAllSongs} allPlaySong={this.state.allPlaySong} saveTrack={this.saveTrack} currentTrack={this.state.currentTrack}
                       changeFeatures={this.changeFeatures} openList={this.state.openList} openListModal={this.openListModel} minutes={this.state.minutes}
                       minutesChange={this.minutesChange} songs_left={this.state.songs_left} songs_right={this.state.songs_right} songs_total={this.state.songs_total}
                       width={this.state.width} dis={this.state.dis} left={this.state.left} widthDisSave={this.widthDisSave} {...this.props}/>
    }
}
