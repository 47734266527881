import * as React from "react";
import "./Playlist.css";
import MotivationThumbImage from '../../../Assets/images/motivation-thumb.png';
import PlaylistModel from "../../Models/PlaylistModel";

interface PlaylistCardProps {
    playlist: PlaylistModel,
    goToDetail: (id: string) => void
}

const PlaylistCard: React.FC<PlaylistCardProps> = ({playlist, goToDetail}) => {
    let image = MotivationThumbImage;
    if (Object.keys(playlist.images).length > 0) {
        image = playlist.images[0].url;
    }
    return <div className="playListItem" onClick={() => goToDetail(playlist.id)}>
        <div className="playListCard">
            <div className="playListThumb">
                <img src={image} alt="Motivation"/>
            </div>
            <div className="playListDetails">
                <div className="playListInfo">
                    <h2>{playlist.name}</h2>
                    <p>by {playlist.owner.display_name}</p>
                </div>
                <div className="playListTrack">
                    <p>{playlist.tracks.total} Tracks</p>
                </div>
            </div>
        </div>
    </div>;
};

export default PlaylistCard;