import React from "react";
import "./Contact.css";

interface ContactProps {
}

const Contact: React.FC<ContactProps> = () => {

 return <div className="contentSection">
  	<div className="contactPage">
  		<h2>Get in touch..</h2>
	    <br/>
	    <p>Interested in joining our Founders team and receiving early access for your studio?</p>
	    <br/>
	    <p>General product feedback or feature requests?</p>
	    <br/>
	    <p>Email us at <strong>hello [at] yogamusiq [dot] com</strong>. We'll be in touch!</p>
  	</div>
  </div>;
};

export default Contact;