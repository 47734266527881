import * as React from 'react';
import {ChangeEvent} from 'react';
import clsx from "clsx";
import AppContext from "../../../Context/app-context";
import {TypeEnum} from "../../Enmus/TypeEnum";
import {getName} from "../../Helpers/Spotify";
import Share from "../../../Assets/images/share.png"

interface SavePlaylistProps {
    sortBy: string,
    savePlaylist: (name: string) => void,
    name: string,
    url: string,
}

interface SavePlaylistState {
    name: string,
}

export default class SavePlaylist extends React.Component<SavePlaylistProps, SavePlaylistState> {
    static contextType = AppContext;

    constructor(props: SavePlaylistProps) {
        super(props);
        this.state = {
            name: ''
        }
    }

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: e.currentTarget.value
        })
    };

    componentWillReceiveProps(nextProps: Readonly<SavePlaylistProps>, nextContext: any): void {
        if (this.context.message !== '' && this.context.type === 'success' && this.state.name !== '') {
            this.setState({
                name: ''
            });
        } else {
            let sortBy = getName(nextProps.sortBy);
            this.setState({
                name: nextProps.name + '-by ' + sortBy
            });
        }
    }

    render() {
        return <>
            <input type="text" className="formControl" placeholder={this.props.name + '-by ' + this.props.sortBy}
                   value={this.props.sortBy !== '' ? this.state.name : ''} onChange={this.onChange}
                   readOnly={this.props.sortBy === ''}
                   onKeyDown={(event) => {
                       if (event.key === "Enter") {
                           this.props.savePlaylist(this.state.name)
                       }
                   }}/>
            <button type="button" className="btnFiltterSave" onClick={() => this.props.savePlaylist(this.state.name)}>Save</button>
            <div style={{width: '150px',textAlign:'center'}}>
                {
                    this.context.message != '' &&
                    <p className={TypeEnum[this.context.type]}>{this.context.message}</p>
                }
                {
                    this.context.message == '' &&
                    <button type="button" className="btnFiltterSave shareButton" onClick={() => {
                        navigator.clipboard.writeText(this.props.url)
                        this.context.setMessage('Copied', TypeEnum.success);
                        setTimeout(() => {
                            this.context.setMessage('', TypeEnum.none);
                        }, 3000)
                    }}>
                        <img src={Share} alt=""/>
                    </button>
                }
            </div>
        </>
    }
}

