import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'

const analytics = Analytics({
    app: 'muvv',
    plugins: [
        segmentPlugin({
            writeKey: 'XJ9fHQmQJuKQ2wDY8HuhjdCPOY7YaZGK'
        })
    ]
})

export const trackPage = () => (
    analytics.page()
)