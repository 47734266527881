import axios from 'axios';

export const get = async (url: string, headers?: object, params?: object): Promise<object> => {
    return new Promise<object>((resolve, reject) => {
        axios.get(url, {
            headers: headers,
            params: params
        }).then((data) => {
            resolve(data['data']);
        }).catch((xhr) => {
            reject(xhr.response.data);
        });
    });
};

export const post = async (url: string, headers?: object, params?: object): Promise<object> => {
    return new Promise<object>((resolve, reject) => {
        axios.post(url, params, {
            headers: headers,
        }).then((data) => {
            resolve(data['data']);
        }).catch((xhr) => {
            console.log(xhr);
            if (xhr.response != undefined) {
                reject(xhr.response.data);
            }
        });
    });
};

export const put = async (url: string, headers?: object, params?: object): Promise<object> => {
    return new Promise<object>((resolve, reject) => {
        axios.put(url, params, {
            headers: headers,
        }).then((data) => {
            resolve(data['data']);
        }).catch((xhr) => {
            console.log(xhr);
            if (xhr.response != undefined) {
                reject(xhr.response.data);
            }
        });
    });
};
