import {get, post, put} from './Ajax';
import {get as getStorage, remove as removeStorage, store} from './Storage';
import PlaylistModel from "../Models/PlaylistModel";
import TrackModel from "../Models/TrackModel";
import FeatureModel from "../Models/FeatureModel";
import {REACT_APP_SPOTIFY_SCOPES} from "../../Constants/spotify_constants";

interface ParsedHashInterface {
    access_token?: string,
    token_type?: string,
    expires_in?: string,
}

interface PlaylistRequest {
    href: string,
    items: Array<PlaylistModel>,
    limit: number,
    next: string | null,
    offset: number
    previous: string | null,
    total: number
}

interface PlaylistTracksRequest {
    href: string,
    items: Array<TrackModel>,
    limit: number,
    next: string | null,
    offset: number
    previous: string | null,
    total: number
}

interface PlaylistInfoRequest {
    audio_features: Array<FeatureModel>,
}

const API_URL = 'https://api.spotify.com/v1/';
const SERVER_URL = process.env.REACT_APP_SPOTIFY_SERVER_URL;

const PLAYLIST_NUMBER = 8;
const TRACK_NUMBER = 100;

export const spotifyAuthLink = (): string => {
    const authEndpoint = process.env.REACT_APP_SPOTIFY_AUTH_END_POINT;
    const clientId = process.env.REACT_APP_SPOTIFY_APP_ID;
    const redirectUri = process.env.REACT_APP_SPOTIFY_REDIRECT_END_POINT;
// @ts-ignore
    const scopes = REACT_APP_SPOTIFY_SCOPES.split(',');
    return `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token&show_dialog=true`;
};

export const parseHash = (hash: string): ParsedHashInterface => {
    return hash.substring(1).split('&').reduce((initial: any, item: string) => {
        if (item) {
            let parts: Array<string> = item.split("=");
            initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
    }, {});
};

export const accessTokenSave = (access_token: string) => {
    store('ACCESS_TOKEN', 'Bearer ' + access_token);
};

export const getAccessToken = (): string | null => {
    return getStorage('ACCESS_TOKEN');
};

export const removeAccessToken = () => {
    removeStorage();
};

export const storeUser = (userdata: object) => {
    store('USER', JSON.stringify(userdata));
};

export const getUser = (): any => {
    let user = getStorage('USER');
    if (user != null) {
        user = JSON.parse(user);
    }
    return user;
};

export const userData = async () => {
    const user = getUser();
    if (user != null) {
        return user;
    } else {
        return new Promise<object>((resolve, reject) => {
            const headers = {
                Authorization: getAccessToken()
            };
            get(API_URL + 'me', headers)
                .then((data) => {
                    storeUser(data);
                    resolve(data)
                })
                .catch((error) => {
                    if (error.error.status === 401) {
                        removeAccessToken();
                        resolve({status: 401})
                    }
                });
        });
    }
};

export const getPlaylists = async (page: number) => {
    return new Promise<PlaylistRequest>((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {
            limit: PLAYLIST_NUMBER,
            offset: (page - 1) * PLAYLIST_NUMBER
        };
        const user = getUser();
        if (user !== null) {
            get(API_URL + `users/${user['id']}/playlists`, headers, params)
                .then((data) => {
                    // @ts-ignore
                    resolve(data)
                })
                .catch((error) => {
                    if (error.error.status === 401) {
                        removeAccessToken();
                        // @ts-ignore
                        resolve({status: 401})
                    }
                });
        }
    });
};

export const getPlaylist = async (id: string) => {
    return new Promise<PlaylistModel>((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {
            fields: "id,name,uri,owner(display_name,id),external_urls(spotify)"
        };

        get(API_URL + `playlists/${id}`, headers, params)
            .then((data) => {
                // @ts-ignore
                resolve(data)
            })
            .catch((error) => {
                if (error.error.status === 401) {
                    removeAccessToken();
                }
            });
    });
};

export const getPlaylistTracks = async (id: string, page: number) => {
    return new Promise<PlaylistTracksRequest>((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {
            limit: TRACK_NUMBER,
            offset: (page - 1) * TRACK_NUMBER,
            fields: "href,items(track(id,duration_ms,uri,name,album(images(height,width,url)),artists(name))),limit,next,offset,previous,total"
        };

        get(API_URL + `playlists/${id}/tracks`, headers, params)
            .then((data) => {
                // @ts-ignore
                resolve(data)
            })
            .catch((error) => {
                if (error.error.status === 401) {
                    removeAccessToken();
                }
            });
    });
};

export const getTracksInfo = async (tracks: string) => {
    return new Promise<PlaylistInfoRequest>((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {
            ids: tracks,
        };

        get(API_URL + `audio-features`, headers, params)
            .then((data) => {
                // @ts-ignore
                resolve(data)
            })
            .catch((error) => {
                if (error.error.status === 401) {
                    removeAccessToken();
                }
            });
    });
};

export const createPlaylist = async (name: string) => {
    return new Promise<PlaylistModel>((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {
            name: name,
            public: false
        };
        const user = getUser();
        if (user != null) {
            post(API_URL + `users/${user['id']}/playlists`, headers, params)
                .then((data) => {
                    // @ts-ignore
                    resolve(data)
                })
                .catch((error) => {
                    if (error.error.status === 401) {
                        removeAccessToken();
                    }
                });
        }
    });
};

export const savePlaylistTracks = async (id: string, uris: Array<string>) => {
    return new Promise<PlaylistModel>((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {
            uris: uris
        };
        const user = getUser();
        if (user != null) {
            post(API_URL + `playlists/${id}/tracks`, headers, params)
                .then((data) => {
                    // @ts-ignore
                    resolve(data)
                })
                .catch((error) => {
                    if (error.error.status === 401) {
                        removeAccessToken();
                    }
                });
        }
    });
};

export const saveUserData = async () => {
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const user = getUser();

        if (user != null) {
            const params = {
                spotify_id: user.id,
                display_name: user.display_name,
                email: user.email,
                json: JSON.stringify(user)
            };
            post(SERVER_URL + `api/v1/users`, headers, params)
                .then((data) => {
                })
                .catch((error) => {
                });
        }
    });
};

export const playSong = async (device_id: string, uris: Array<string>, position_ms = 60) => {
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };
        let params = {};
        if (position_ms > 0) {
            // @ts-ignore
            params['position_ms'] = position_ms * 1000;
            // @ts-ignore
            params['uris'] = uris;
        }
        get(API_URL + "me/player/devices", headers).then((response) => {
            console.log("response", response)

            put(API_URL + `me/player/play?device_id=${device_id}`, headers, params)
                .then((data) => {
                    resolve(data)
                })
                .catch((error) => {
                    resolve(error)
                });
        });
    });
}

export const pauseSong = async (device_id: string) => {
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {};

        put(API_URL + `me/player/pause?device_id=${device_id}`, headers, params)
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                resolve(error)
            });
    });
}

export const song_skip = async (device_id: string, position_ms = 60) => {
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {};

        put(API_URL + `me/player/seek?device_id=${device_id}&position_ms=${position_ms}`, headers, params)
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                resolve(error)
            });
    });
}

export const next_song = async (device_id: string) => {
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        const params = {};

        post(API_URL + `me/player/next?device_id=${device_id}`, headers, params)
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                resolve(error)
            });
    });
}
export const current_playing = async () => {
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: getAccessToken()
        };

        get(API_URL + `me/player/currently-playing`, headers)
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                resolve(error)
            });
    });
}

export const getFeatureColors = (sortBy: string): Array<number> => {
    let colours = {
        'tempo': [177, 74, 253],
        'energy': [250, 30, 116],
        'danceability': [253, 213, 0],
        'loudness': [77, 171, 255],
        'valence': [121, 202, 0],
        'instrumentalness': [242, 65, 228],
        'speechiness': [0, 216, 253],
        'special_sauce': [233, 89, 51],
    };
    // @ts-ignore
    return colours[sortBy];
};


export const getName = (sortBy: string): string => {
    let names = {
        'tempo': "Tempo",
        'energy': "Energy",
        'danceability': "Danceability",
        'loudness': "Loudness",
        'valence': "Mood",
        'instrumentalness': "Instrumentalness",
        'speechiness': "Talkability",
        'special_sauce': "Special Sauce",
    };
    // @ts-ignore
    return names[sortBy];
};
