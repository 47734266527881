import * as React from "react";
import "../../Assets/css/ribbon.css";
import "./Master.css";
import Header from "../Includes/HeaderContainer";
import Content from "../Page/ContentContainer";

const Master: React.FC = (props) => {
    return <div>
        <Header/>
        <Content/>
        {/*This footer needs to be placed somewhere else- I see issues with
        the footer floating around. commenting it out for now*/}
        {/*<Footer></Footer>*/}
    </div>;
};

export default Master;
