import * as React from "react";
import UserProfile from "./UserProfile";
import AppContext from "../../Context/app-context";

interface UserProfileProps {

}

interface UserProfileState {
    open: boolean,
}

export default class UserProfileContainer extends React.Component<UserProfileProps, UserProfileState> {
    static contextType = AppContext;

    constructor(props: UserProfileProps) {
        super(props);
        this.state = {
            open: false
        }
    }

    openModel = () => {
        this.setState({
            open: !this.state.open
        });
    }


    render() {
        if (Object.keys(this.context.user).length > 0) {
            return <UserProfile user={this.context.user} logout={this.context.logout} open={this.state.open}
                                openModel={this.openModel}/>;
        }
        return <span/>
    }
}