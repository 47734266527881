import * as React from "react";
import Founders from "./Founders";
import { RouteComponentProps, withRouter } from "react-router";
import FoundersModel from "../../Models/FoundersModel";
import axios from "axios";

interface FoundersProps extends RouteComponentProps {}
interface FoundersState {
  foundersArray: Array<FoundersModel>;
}

class FoundersContainer extends React.Component<FoundersProps, FoundersState> {
  constructor(props: FoundersProps) {
    super(props);

    this.state = {
      foundersArray: []
    };
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_SPOTIFY_SERVER_URL}api/v1/founder`)
      .then(res => {
        this.setState({ foundersArray: res.data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return <Founders foundersArray={this.state.foundersArray} />;
  }
}
export default withRouter(FoundersContainer);